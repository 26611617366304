<script>

export default {
  layout: 'unauthenticated',

  async asyncData({ redirect, store, router }) {

    await store.dispatch('auth/logout', null, { root: true });

    window.location.href = '/';
    var cookies = document.cookie.split(";");
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }

    let url = 'apiauth/common/deleteCookie'
    fetch(url).then(res => { })

  }
};
</script>

<template>
  <main class="main-layout">
    <div>
      <h1 v-t="'logout.message'" />
    </div>
  </main>
</template>
<style lang="scss" scoped>
main>div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
</style>
